import { Swiper } from "swiper";
import { Navigation, Pagination, Mousewheel, Grid } from "swiper/modules";
import { FreeMode } from "swiper/modules";
Swiper.use([Navigation]);

new Swiper(".new__slider", {
  modules: [Navigation, Pagination],
  slidesPerView: "auto",
  spaceBetween: 20,
  watchSlidesProgress: true,
  slidesPerGroup: 1,
  navigation: {
    prevEl: ".new__btn--prev",
    nextEl: ".new__btn--next",
  },
  pagination: {
    el: ".new__pagination .swiper-pagination",
    type: "progressbar",
  },
});
let mouse = true;

window.addEventListener("DOMContentLoaded", () => {
  const resizableSwiper = (
    breakpoint,
    swiperClass,
    swiperSettings,
    callback
  ) => {
    let swiper;

    breakpoint = window.matchMedia(breakpoint);

    const enableSwiper = function (className, settings) {
      swiper = new Swiper(className, settings);

      if (callback) {
        callback(swiper);
      }
    };

    const checker = function () {
      if (breakpoint.matches) {
        return enableSwiper(swiperClass, swiperSettings);
      } else {
        if (swiper !== undefined) swiper.destroy(true, true);
        return;
      }
    };

    breakpoint.addEventListener("change", checker);
    checker();
  };

  resizableSwiper("(max-width: 1024px)", ".popular__slider--pop", {
    modules: [Navigation, Grid],
    slidesPerView: 5,
    speed: 600,
    navigation: {
      prevEl: ".pop-prev",
      nextEl: ".pop-next",
    },
    slidesPerView: 3,
    spaceBetween: 10,
    grid: {
      fill: "row",
      rows: 2,
    },
  });

  resizableSwiper("(max-width: 1024px)", ".cat__slider", {
    modules: [Navigation, Grid],
    speed: 600,
    navigation: {
      prevEl: ".cat-prev",
      nextEl: ".cat-next",
    },
    slidesPerView: 3,
    spaceBetween: 10,
    grid: {
      fill: "row",
      rows: 2,
    },
  });

  resizableSwiper("(max-width: 1024px)", ".popular__slider--rec", {
    modules: [Navigation, Grid],
    speed: 600,
    navigation: {
      prevEl: ".rec-prev",
      nextEl: ".rec-next",
    },
    slidesPerView: 3,
    spaceBetween: 10,
    grid: {
      fill: "row",
      rows: 2,
    },
  });

  const header = document.querySelector(".header");
  const headerLogo = header.querySelector(".header__logo");
  const headerContainer = header.querySelector(".header__container");

  const menuEq = document.querySelector(".equipment__menu");
  const menu = document.querySelector(".menu");
  const mBtn = document.querySelector(".header__m");
  const slides = document.querySelectorAll(
    ".main-slider>.swiper-wrapper>.swiper-slide"
  );
  const lastSlide = slides[2];
  function checkMenuEq() {
    if (menuEq.classList.contains("active")) {
      const menuText = menuEq.querySelector("span");
      menuText.style.transform = "translateX(15rem)";

      setTimeout(() => {
        menuText.textContent = "Меню";
        menuText.style.transform = null;
        menu.classList.remove("active");
      }, 300);
    }
  }
  const someFunc = (instance) => {
    if (instance) {
      instance.on("slideChange", function (e) {
        if (window.matchMedia("(min-width: 1025px)").matches) {
          if (instance.activeIndex == 1) {
            header.style.transform = "translateY(-100%)";
            header.classList.remove("logo-down");
            header.classList.remove("active");
            header.classList.remove("is-third");
            mBtn.classList.remove("active");
            setTimeout(() => {
              headerContainer.style.background = "#E8E8E8";
              headerLogo.style.display = "none";
              header.classList.add("high-width");
            }, 500);
          } else if (instance.activeIndex == 2) {
            checkMenuEq();
            setTimeout(() => {
              header.style.transform = "translateY(-100%)";
              headerContainer.style.background = "#E8E8E8";
            }, 500);
            setTimeout(() => {
              headerLogo.style.display = null;
              header.style.transform = "translateY(-50%)";
              header.classList.remove("high-width");
              mBtn.classList.remove("active");
              header.classList.add("logo-down");
              header.classList.add("is-third");
            }, 1000);
          } else {
            checkMenuEq();

            headerContainer.style.background = null;
            header.classList.remove("active");
            setTimeout(() => {
              header.style.transform = "translateY(-100%)";
              headerLogo.style.display = null;
            }, 500);
            setTimeout(() => {
              header.style.transform = null;
              header.classList.remove("high-width");
              header.classList.remove("logo-down");
              header.classList.remove("is-third");
            }, 1000);
          }
        } else {
          if (instance.activeIndex == 1) {
            header.style.transform = "translateY(-110%)";
            menuBtn.removeEventListener("click", defaultMenu);
            menuBtn.addEventListener("click", progressMenu);
            setTimeout(() => {
              headerContainer.style.background = "#E8E8E8";
            }, 500);
          } else if (instance.activeIndex == 2) {
            header.style.transform = null;
            lastSlide.classList.add("nowheel");
            setTimeout(() => {
              headerContainer.style.background = "#E8E8E8";
            }, 500);
          } else {
            headerContainer.style.background = null;
            header.style.transform = null;
            menuBtn.removeEventListener("click", progressMenu);
            menuBtn.addEventListener("click", defaultMenu);
          }
        }
      });
    }
  };

  resizableSwiper(
    "(min-width: 320px)",
    ".main-slider",
    {
      modules: [Mousewheel, FreeMode],
      slidesPerView: 1,
      speed: 500,
      simulateTouch: false,
      direction: "vertical",
      mousewheelControl: mouse,
      mousewheel: {
        noMousewheelClass: "nowheel",
        releaseOnEdges: true,
      },
    },
    someFunc
  );
  resizableSwiper("(min-width: 1025px)", ".popular__slider--rec", {
    modules: [Navigation],
    slidesPerView: 5,
    speed: 600,
    navigation: {
      prevEl: ".rec-prev",
      nextEl: ".rec-next",
    },
  });
  resizableSwiper("(min-width: 1025px)", ".popular__slider--pop", {
    modules: [Navigation],
    slidesPerView: 5,
    speed: 600,
    navigation: {
      prevEl: ".pop-prev",
      nextEl: ".pop-next",
    },
  });

  const menuBtn = document.querySelector(".header__burger");
  const eqMenuBtn = document.querySelector(".eq__menu");
  const siteContainer = document.querySelector(".site-container");

  function defaultMenu() {
    let isActive = menu.classList.toggle("active");
    siteContainer.classList.toggle("menu-active");

    document.body.style.overflow = isActive ? "hidden" : null;
  }

  function progressMenu() {
    let isActive = menu.classList.toggle("active");

    document.body.style.overflow = isActive ? "hidden" : null;

    setTimeout(() => {
      header.style.transform = isActive ? "translateY(0)" : "translateY(-110%)";
      siteContainer.classList.toggle("menu-active");
    }, 600);
  }

  menuBtn.addEventListener("click", defaultMenu);
  eqMenuBtn.addEventListener("click", (e) => {
    let isActive = menu.classList.toggle("active");
    siteContainer.classList.toggle("menu-active");

    header.style.transform = isActive ? "translateY(0)" : "translateY(-110%)";
    document.body.style.overflow = isActive ? "hidden" : null;
  });

  lastSlide.addEventListener("scroll", (e) => {
    if (lastSlide.scrollTop > 0) {
      lastSlide.classList.add("nowheel");
    } else if (lastSlide.scrollTop <= 0) {
      lastSlide.classList.remove("nowheel");
    }
  });
  let eqSlider;
  const eqItems = document.querySelectorAll(".panel__item");
  if (window.matchMedia("(max-width: 1024px)").matches) {
    eqSlider = new Swiper(".equipment__slider", {
      modules: [Pagination],
      slidesPerView: 1,
      pagination: {
        el: ".equipment__pagination",
      },
      speed: 600,

      on: {
        slideChange: (swiper) => {
          eqItems.forEach((el) => el.classList.remove("active"));
          eqItems[swiper.activeIndex].classList.add("active");
        },
      },
    });
  }
  if (eqSlider) {
    eqItems.forEach((item, index) => {
      item.addEventListener("click", (e) => {
        eqSlider.slideTo(index);
      });
    });
  }
});
